
.buttons-container {
    margin: 0;
    min-width: 200px; /* Need a specific value to work */
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 1em;
    position: relative;
}

.bi {
    cursor: pointer;
}